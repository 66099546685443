@mixin title() {
  margin: 0 0 16px 0;
  color: var(--color-text);
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
}

@mixin sub-title() {
  color: var(--color-text);
  font-weight: 500;

  @media (min-width: 769px) {
    margin: 0 0 20px 0;
    font-size: 20px;
    line-height: 22px;
  }

  @media (max-width: 768px) {
    margin: 0 0 10px 0;
    font-size: 15px;
    line-height: 17px;
  }
}

@mixin back-title() {
  margin: 0 0 16px 0;
  display: flex;
  div {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    cursor: pointer;
    &:hover {
      .icon {
        border-color: var(--color-link);
      }
    }
    .icon {
      width: 25px;
      height: 25px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 9px;
      border-radius: 5px;
      border: 1px solid #161618;
    }
  }
}

@mixin content-title() {
  @include title-font;
  color: var(--color-text);
  text-align: center;

  @media (min-width: 769px) {
    margin: 50px 10%;
    font-size: 54px;
    line-height: 56px;
  }

  @media (max-width: 768px) {
    margin: 30px 0;
    font-size: 36px;
    line-height: 38px;
  }
}

@mixin title-font() {
  font-weight: 400;
  font-family: 'Bebas Neue', sans-serif;
}

@mixin toggle-password-visibility() {
  position: relative;

  .icon {
    padding: 5px;
    color: var(--color-text);
    font-size: 13px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;

    &.icon-Closed-Eye {
      color: #55585c;
      font-size: 16px;
    }
  }
}

@mixin drop-down() {
  position: absolute;
  width: 140px;
  top: 45px;
  right: 0;
  background: #1f1f22;
  border-radius: 5px;

  ul {
    margin: 0;
    list-style: none;
    padding: 0;

    li {
      padding: 11px 12px;
      font-size: 14px;
      font-weight: 400;
      border-bottom: 1px solid #27272c;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        color: var(--color-link);
      }

      &:last-child {
        border-bottom: none;
      }
    }

    // .red {
    //   color: #f93535;

    //   &:hover {
    //     color: #b72828;
    //   }
    // }
  }
}

@mixin live-label() {
  width: 44px;
  height: 22px;
  position: absolute;
  top: 12px;
  left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ea2b2b;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 10px;
  letter-spacing: 0.42px;
}

@mixin form {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__field {
    width: 100%;
    display: flex;
    align-items: flex-start;

    @media (max-width: 768px) {
      flex-flow: column;
    }

    label {
      display: block;
      font-weight: 400;

      @media (min-width: 769px) {
        width: 178px;
        font-size: 15px;
        line-height: 44px;
      }

      @media (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 6px;
      }
    }

    &-group {
      width: 100%;
      position: relative;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      @media (min-width: 769px) {
        max-width: 574px;
        margin: 0 0 16px 0;
      }

      @media (max-width: 768px) {
        margin: 0 0 18px 0;
      }

      input,
      select,
      textarea {
        width: 100%;
        height: 44px;
        margin: 0;
        padding: 0 15px;
        flex-grow: 1;
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        border-radius: 5px;
        border: 1px solid #242428;
        background: #09090a;
        transition: all 0.2s;

        &.ng-invalid.ng-dirty {
          border: 1px solid var(--color-input-error);
        }

        &:disabled {
          background: #1d1d22;
        }
      }
    }
  }

  &__error {
    @include form-error;
  }
}

@mixin form-error {
  display: block;
  margin-top: 5px;
  color: #dc0e10;
  font-size: 13px;
  line-height: 16px;

  mark {
    padding: 0 4px;
    color: #fff;
    background: #ac1819;
    border-radius: 3px;
  }
}

@mixin form-modal {
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  label {
    position: relative;
    display: block;
    margin-top: 14px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  &__fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  &__field {
    width: 100%;
    margin: 3px 0 0;
    padding: 10px 10px;
    display: block;
    position: relative;
    color: #fff;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    border-radius: 5px;
    border: 1px solid #2f2f37;
    background: #161618;

    &:not(textarea) {
      height: 40px;
    }

    &.ng-invalid.ng-dirty {
      border: 1px solid var(--color-input-error);
    }

    &__error {
      display: block;
      margin-top: 5px;
      color: var(--color-input-error);
      font-size: 13px;
      line-height: 16px;

      mark {
        padding: 0 4px;
        color: #000;
        background: var(--color-input-error);
        border-radius: 3px;
      }
    }
  }

  app-button {
    width: 100%;
    margin-top: 20px;
  }
}

@mixin checkbox($checkboxSize, $textSize) {
  width: fit-content;
  min-height: $checkboxSize;
  padding-left: calc($checkboxSize + 8px);
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  cursor: pointer;

  input {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    width: $checkboxSize;
    height: $checkboxSize;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: calc($checkboxSize / 2);
    border-radius: 5px;
    background-color: #2c2c37;

    &::before {
      display: none;
    }

    &.error {
      border: 1px solid var(--input-error);
    }
  }

  .text {
    font-size: $textSize;
  }

  input:checked ~ .checkmark {
    background-color: var(--color-hightlight);

    &::before {
      display: block;
    }
  }
}

@mixin carousel-modal() {
  height: 100%;
  max-width: 992px;
  min-height: inherit;
  max-height: inherit;
  position: relative;

  @media (min-width: 769px) {
    width: 70vw;
  }

  @media (max-width: 768px) {
    width: 100vw;
  }

  &-wrapper {
    height: 100%;
    min-height: inherit;
    max-height: inherit;
  }

  &-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      object-fit: contain;
    }

    .player-iframe-container {
      @include iframe-container();
    }

    &__empty {
      width: 100%;
    }
  }

  &-controls {
    @include carousel-controls();
  }

  &-preview {
    @include carousel-preview();
  }
}

@mixin carousel-preview() {
  width: 100%;
  margin: 8px 0 0;

  drag-scroll {
    width: 100%;

    ::ng-deep {
      .drag-scroll-content {
        display: flex !important;
        width: 100% !important;
        height: 100% !important;
        padding: 0 0 5px 0;
      }
    }
  }

  &__item {
    margin-right: 1px !important;
    @include moments-border();

    &-wrapper {
      display: flex;
      flex-flow: column;
      height: 100%;
      padding: 2px;
      background: var(--color-bg);
      border-radius: 5px;
      user-select: none;

      .thumbnail {
        width: auto;
        height: 65px;
        min-width: 120px;
        max-width: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }
  }
}

@mixin video-wrapper() {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .play {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-light);
    border-radius: 50%;
    background-color: #fff;
    transition: all 1s;
    cursor: pointer;

    @media (min-width: 769px) {
      width: 40px;
      height: 40px;
      font-size: 12px;
    }

    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
      font-size: 10px;
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  .unlock {
    @include unlock-btn;
  }

  img {
    width: 100%;
    border-radius: 10px;
  }
}

@mixin unlock-btn() {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  padding: 6px;
  font-size: 12px;
  border-radius: 5px;
  background: #2f3137;
  cursor: pointer;

  .icon {
    margin-left: 6px;
  }
}

@mixin carousel-controls() {
  &-prev-wrapper,
  &-next-wrapper {
    position: absolute;
    width: 140px;
    max-width: 20%;
    height: 100%;
    cursor: pointer;
  }

  &-prev-wrapper {
    left: 0;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    border-radius: 10px 0 0 10px;
  }

  &-next-wrapper {
    right: 0;
    background: linear-gradient(-90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    border-radius: 0 10px 10px 0;
  }

  &-prev,
  &-next {
    width: 34px;
    height: 34px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-light);
    font-size: 12px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.5);
    transition: all 0.5s;
    cursor: pointer;

    &.disabled {
      display: none;
    }

    &:hover {
      color: var(--color-light);
      background: rgba(255, 255, 255, 0.7);
    }
  }
  &-prev {
    left: 12px;

    span {
      margin-left: -2px;
    }
  }
  &-next {
    right: 12px;

    span {
      margin-left: 2px;
    }
  }
}

@mixin moments-border() {
  display: flex;
  justify-content: center;
  flex-flow: column;
  flex-shrink: 0;
  padding: 1px;
  background: #2a2e35;
  border-radius: 5px;
  cursor: pointer;

  &.active {
    background: linear-gradient(108.14deg, #7000ff 0%, #ff00b8 100%);
    background-clip: padding-box;
  }
}

@mixin iframe-container() {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  border-radius: 10px;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    background-color: var(--color-bg);
  }
}

@mixin actions-row() {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  padding: 0 0 8px 0;

  h3 {
    @include sub-title();
    white-space: nowrap;

    @media (min-width: 769px) {
      margin: 0 12px 4px 0;
    }

    @media (max-width: 768px) {
      width: 100%;
      flex-shrink: 0;
      margin: 0 12px 4px 0;
    }
  }

  &__show-more {
    margin: auto 0 7px auto;
    font-size: 16px;
  }
}

@mixin coming-soon() {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  min-height: 132px;
  padding: 100px 10% 20px;
  border-radius: 10px;
  border: 1px solid #1b1b1b;
  background: #0c0c0e;
  background-image: url('~src/assets/icons/logo-shadow.svg');
  background-repeat: no-repeat;
  background-position: 50% 24px;

  span {
    color: var(--color-text-dark);
    font-size: 17px;
    font-weight: 400;
    line-height: 23px;
  }
}

@mixin faqs-list() {
  mat-expansion-panel::ng-deep {
    .mat-expansion-panel-body {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;

      h2 {
        margin: 0 0 20px 0;
        font-size: 22px;
        font-weight: 500;
        line-height: 26px;
      }

      h3 {
        margin: 0 0 10px 0;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
      }

      ul {
        li {
          list-style: disc;
        }
      }

      ul,
      ol {
        @media (min-width: 769px) {
          padding-left: 30px;
        }
        @media (max-width: 768px) {
          padding-left: 25px;
        }

        li {
          padding-bottom: 10px;
        }

        ul,
        ol {
          @media (min-width: 769px) {
            padding: 10px 0 10px 50px;
          }
          @media (max-width: 768px) {
            padding: 10px 0 10px 30px;
          }
        }
      }

      img {
        margin: 20px 0;
        display: block;
        max-height: 512px;
        border-radius: 10px;
      }
    }
  }
}

@mixin reaction() {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 28px;
  padding: 0 4px;
  border: 1px solid #1e1e20;
  border-radius: 5px;
  cursor: pointer;
}

@mixin reactions-popover() {
  display: flex;
  align-items: stretch;
  position: absolute;
  bottom: calc(100% + 7px);
  left: -10000px;
  background: #24272e;
  border-radius: 5px;
  z-index: 102;

  &.visible {
    &.position-center {
      left: 14px;
      transform: translateX(-50%);

      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.position-left {
      left: auto;
      right: 0;

      &::after {
        right: 7px;
      }
    }

    &.position-right {
      left: 0;

      &::after {
        left: 7px;
      }
    }

    &.position-center-right {
      @media (min-width: 1580px) {
        left: 14px;
        transform: translateX(-50%);
      }

      @media (max-width: 1579px) {
        left: 0;
      }

      &::after {
        @media (min-width: 1580px) {
          left: 50%;
          transform: translateX(-50%);
        }

        @media (max-width: 1579px) {
          left: 7px;
        }
      }
    }
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    display: block;
    top: 100%;
    margin-top: -1px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #24272e;
  }
}

@mixin close-icon() {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--color-text);
  font-size: 8px;
  border-radius: 5px;
  border: 1px solid #2a2c30;
  transition: all 0.25s;
  cursor: pointer;

  &:hover {
    color: var(--color-link);
  }
}

@mixin author-label() {
  padding: 5px 6px;
  display: flex;
  align-items: center;
  background: #151517;
  border-radius: 5px;

  img {
    width: 26px;
    height: 26px;
    border-radius: 5px;
    margin-right: 6px;
  }

  p {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
  }
}

@mixin share-btn() {
  height: 36px;
  margin: 4px 0;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 15px;
  font-weight: 500;
  background: #151517;
  border-radius: 5px;
  border: 1px solid #292b2e;
  cursor: pointer;

  &:hover {
    border-color: #5e636d;
  }

  span {
    margin-right: 8px;
    font-size: 18px;
  }
}

@mixin tabs-list() {
  display: flex;
  align-items: center;

  li {
    display: flex;
    align-items: center;
    margin: 0 8px 0 0;
    color: #fff;
    font-weight: 400;
    border-radius: 100px;
    background: #151517;
    transition: all 1s;
    cursor: pointer;

    @media (min-width: 992px) {
      height: 40px;
      padding: 0 16px;
      font-size: 15px;
    }

    @media (max-width: 991px) {
      height: 30px;
      padding: 0 10px;
      font-size: 13px;
    }

    &.active {
      color: #343434;
      background: #fff;
    }
  }
}

@mixin icon-button {
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0 4px;
  color: white;
  border: 1px solid #2a2e35;
  border-radius: 5px;
  background: transparent;
  cursor: pointer;

  width: 24px;
  height: 24px;
  font-size: 12px;
}
