@import './assets/icomoon/style.css';
@import './assets/scss/normalize';
@import '../mixins';
@import 'ngx-toastr/toastr';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '@ctrl/ngx-emoji-mart/picker';
// @import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Press+Start+2P&display=swap');

* {
  box-sizing: border-box;
  outline: none;
}

body,
html {
  min-width: 100%;
  min-height: 100%;
}

body {
  width: 100%;
  display: block;
  background-color: var(--color-bg);
  color: var(--color-text);
  line-height: 1;
  font-family: 'Roboto', sans-serif;

  @media (max-width: 768px) {
    &:not(.home-page-template) {
      padding-bottom: var(--mobile-menu-height);
    }
  }
}

:root {
  --header-height: 72px;
  --header-height-mobile: 60px;

  --color-text: #fff;
  --color-text-dark: #5e636d;
  --color-light: #343434;
  --color-bg: #09090a;
  --color-bg-secondary: #151517;
  --color-input-error: #821718;
  --color-link: #1a79e8;
  --color-status-success: #09ca56;
  --color-status-error: #ee1c1c;

  // buttons
  --color-btn-primary: #692ad1;
  --color-btn-primary-hover: #2820ac;

  --color-btn-light: var(--color-light);
  --bg-btn-light: #fff;
  --bg-btn-light-hover: #e7e7e7;

  --color-btn-dark: #fff;
  --bg-btn-dark: #0b0d10;
  --bg-btn-dark-hover: #343a50;
  --border-btn-dark: #343a50;

  --color-btn-dark-filled: #fff;
  --bg-btn-dark-filled: #131315;
  --bg-btn-dark-filled-hover: #343a50;
  --border-btn-dark-filled: #131315;

  --color-btn-filled: #fff;
  --bg-btn-filled: #2c2c37;
  --bg-btn-filled-hover: #343a50;

  --color-hightlight: #692ad1;
  --color-border: #151617;
  --color-border-dark: rgba(21, 21, 23, 1);
  --color-text-secondary: #a8a8bd;

  --bg-bth-twitch-collapse: #5252607a;
  --color-athens-gray: #efeff1;

  --mobile-menu-height: 58px;
  --desktop-bottom-padding: 24px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-text) !important;
  -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #313439;
  box-shadow: none;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}

iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

a {
  color: var(--color-link);
  text-decoration: none;

  &:hover {
    color: var(--color-link);
    text-decoration: underline;
  }
}

w3m-modal {
  z-index: 1000;
}

.container {
  width: 100%;
  max-width: 1168px;
  min-height: 100%;
  margin: 0 auto;

  @media (min-width: 1300px) {
    width: 80%;
  }

  @media (min-width: 769px) and (max-width: 1299px) {
    padding: 0 20px 0 124px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }

  @media (max-width: 480px) {
    &.mobile-without-padding {
      padding-right: 0;
    }
  }
}

.home-video,
.home-wide-container {
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.home-video {
  max-width: 1200px;
}

.home-wide-container {
  max-width: 1436px;
}

.toast-custom {
  font-size: 13px;
}

:focus,
:active {
  outline: none;
}

.desktop-only {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.toast-container {
  width: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  right: 24px;

  .ngx-toastr {
    min-height: 106px;
    margin: 0 0 10px;
    padding: 30px 30px 30px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    box-shadow: none;

    @media (min-width: 769px) {
      width: 420px;
    }

    @media (max-width: 768px) {
      width: 320px;
    }

    &:hover {
      box-shadow: none;
    }

    &:before {
      width: 46px;
      height: 46px;
      position: absolute;
      top: 30px;
      left: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: icomoon;
      font-size: 20px;
      border-radius: 50%;
    }

    &.toast-success {
      background: var(--color-bg-secondary);

      &:before {
        content: '\e91e';
        background: #30e2a4;
      }
    }

    &.toast-warning {
      background: var(--color-bg-secondary);

      &:before {
        content: '\e90d';
        background: #e7ac14;
      }
    }

    &.toast-error {
      background: var(--color-bg-secondary);

      &:before {
        content: '\e92a';
        background: #dd185f;
      }
    }

    &.toast-info {
      background: var(--color-bg-secondary);

      &:before {
        content: '\e91e';
        background: #e7ac14;
      }
    }

    &.toast-notification {
      background: #1e1e24;
      min-height: 60px;
      padding: 12px 16px 12px 60px;

      @media (max-width: 567px) {
        width: 100%;
      }

      &:before {
        top: 12px;
        left: 16px;
        content: url('./assets/gladiators-notification-logo.svg');
        background: #2b2b37;
        width: 36px;
        height: 36px;
      }

      .toast-title {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
      }

      .toast-message {
        font-size: 14px;
        line-height: 14px;
      }
    }

    .toast-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }

    .toast-message {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      word-break: break-word;

      a {
        color: var(--color-link);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .toast-close-button {
      position: absolute;
      right: 10px;
      top: 0;
      font-size: 31px;
      font-weight: 400;
      text-shadow: none;
      transition: all 0.25s;

      &:focus,
      &:hover {
        color: var(--color-link);
        opacity: 1;
      }
    }

    &.border {
      border: 1px solid #444;
    }
  }
}

.cdk-overlay-container {
  .cdk-overlay-backdrop {
    background: rgba(0, 0, 0, 0.5);
  }

  .dialog-overlay-pane {
    overflow-y: auto;
    max-width: 90vw !important;
    border-radius: 10px;

    &.dialog-image-preview {
      width: 90%;
      border-radius: 10px;
      @media (min-width: 769px) {
        max-width: 550px !important;
      }
      @media (max-width: 768px) {
        max-width: 500px !important;
      }
    }

    &.dialog-video-preview {
      margin: 100px 0;
      z-index: unset; //fix for safati overflow and fixed position

      @media (max-width: 768px) {
        max-width: 100% !important;
      }

      .mat-mdc-dialog-container {
        padding: 0;
        overflow: hidden;

        .mdc-dialog__surface {
          padding: 0;
          border: none;
          background: transparent;
        }
      }
    }

    &.dialog-meld {
      @media (max-width: 768px) {
        max-width: 100% !important;
      }
    }

    &.full-screen {
      width: 100% !important;
      max-width: none !important;
      min-height: 100dvh;

      .mat-mdc-dialog-container {
        .mdc-dialog__surface {
          border: 0;
          border-radius: 0;

          .modal-content {
            width: 100%;
            max-width: 400px;
          }
        }
      }
    }

    .mat-mdc-dialog-container {
      position: relative;
      border-radius: 10px;

      .mdc-dialog__content {
        line-height: unset;
        letter-spacing: unset;
        color: var(--color-text);
      }

      .mdc-dialog__surface {
        padding: 0;
        overflow: hidden;
        border-radius: 10px;
        border: 1px solid #2c2c33;
        background: #161618;
      }

      .close-icon {
        @include close-icon;
      }
    }

    // .mat-mdc-dialog-component-host > * {
    //   width: 100%;
    // }

    .header {
      min-height: 50px;
      padding: 14px 45px 14px 20px;
      display: flex;
      flex-flow: column;
      border-bottom: 1px solid #2c2c33;

      h2 {
        margin: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }

      &.with-icon {
        padding-top: 9px;
        padding-bottom: 9px;

        h2 {
          display: flex;
          align-items: center;
          column-gap: 5px;

          img {
            height: 30px;
          }
        }
      }
    }

    .modal {
      max-width: 90vw;
    }

    .modal-content {
      padding: 10px 24px 20px 24px;

      &__buttons-list {
        margin-top: 10px;
        display: flex;

        app-button {
          width: 50%;

          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }

    .modal-footer {
      padding: 14px 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      border-top: 1px solid #2c2c33;
    }
  }
}

// accordion

.mat-accordion {
  .mat-expansion-panel {
    color: var(--color-text);
    background: transparent !important;
    border-bottom: 1px solid #151516 !important;
    box-shadow: none !important;

    .mat-expansion-panel-header {
      height: auto;
      min-height: 60px;
      padding: 20px 20px 10px 0;
      background: transparent !important;

      .mat-expansion-panel-header-title {
        margin-top: 5px;
        color: var(--color-text);
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }

      .mat-expansion-indicator {
        &::after {
          color: var(--color-text);
          border-width: 0 1px 1px 0;
        }
      }
    }

    .mat-expansion-panel-body {
      padding: 10px 0;
    }
  }
}

.html-content {
  @media (min-width: 769px) {
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  p {
    margin: 0 0 10px 0;
  }

  h3 {
    margin: 38px 0 16px 0;
    font-weight: 400;

    @media (min-width: 769px) {
      font-size: 28px;
      line-height: 32px;
    }

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  ul {
    li {
      list-style: disc;
    }
  }

  ul,
  ol {
    margin: 20px 0 10px 0;

    @media (min-width: 769px) {
      padding-left: 30px;
    }

    @media (max-width: 768px) {
      padding-left: 15px;
    }

    li {
      padding-bottom: 10px;
    }

    ul,
    ol {
      margin: 0;
      @media (min-width: 769px) {
        padding: 10px 0 10px 20px;
      }

      @media (max-width: 768px) {
        padding: 10px 0 10px 20px;
      }
    }
  }

  ol {
    counter-reset: item;

    > li {
      counter-increment: item;
    }

    ol > li {
      display: block;

      &:before {
        content: counters(item, '.') '. ';
        margin-left: -20px;
      }
    }
  }

  ol img {
    margin: 20px 0;
    display: block;
  }
}

// dropdown-singleselect-label

.mat-mdc-form-field.dropdown-singleselect-label {
  .mdc-text-field {
    height: 30px;
    padding: 0 13px;
    background: #222226;
    border-radius: 5px;

    .mat-mdc-form-field-flex {
      height: 100%;
      align-items: center;

      .mat-mdc-form-field-infix {
        display: flex;
        align-items: center;
        width: auto;
        min-height: auto;
        padding: 0;

        label {
          position: static;
          width: 100%;
          max-width: none;
          margin-right: 5px;
          color: #8e8e9f;
          font-size: 14px;
          letter-spacing: normal;
          transform: none;
        }

        mat-select {
          color: #fff;
          font-size: 14px;
          letter-spacing: normal;

          .mat-mdc-select-arrow-wrapper {
            height: auto;
            transform: none;

            .mat-mdc-select-arrow {
              width: auto;
              height: auto;
              margin-left: 10px;
              color: #8e8e9f;
              font-size: 8px;
              font-family: 'icomoon' !important;
              transition: 0.5s all;

              &:before {
                content: '\e922';
              }

              svg {
                display: none;
              }
            }
          }
        }
      }
    }

    .mdc-line-ripple {
      display: none;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  &.mat-focused {
    .mat-mdc-select-arrow {
      transform: rotate(180deg);
    }
  }
}

// dropdown-singleselect-list
.mat-mdc-select-panel.dropdown-singleselect-list {
  max-height: 225px;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 0;
  background: #222226;
  border-radius: 5px !important;

  .mat-mdc-option {
    height: 37px;
    min-height: auto;
    padding: 0 12px;
    color: #fff;
    font-size: 14px;
    border-bottom: 1px solid #333339;

    &:last-child {
      border-bottom: none;
    }

    .mdc-list-item__primary-text {
      letter-spacing: normal;
    }

    .mat-pseudo-checkbox {
      &::after {
        width: 10px;
        height: 4px;
        color: #fff;
      }
    }

    .mdc-list-item__primary-text {
      color: #fff !important;
    }
  }
}

// Angular material tabs

mat-tab-group {
  mat-tab-header {
    .mat-mdc-tab {
      font-size: 18px;
      letter-spacing: 1px;

      .mdc-tab__text-label {
        color: var(--color-btn-primary);
      }

      &:hover {
        .mdc-tab__text-label {
          color: var(--color-btn-primary);
        }
      }

      .mdc-tab-indicator {
        &__content {
          opacity: 0.3;
        }
      }

      &.mdc-tab--active {
        .mdc-tab-indicator__content {
          opacity: 1;
        }
      }
    }
  }
}

.sheet-fan-area-menu {
  margin-bottom: 58px !important;
  z-index: 1 !important;

  .mat-bottom-sheet-container {
    border-radius: 24px 24px 0 0;
    background-color: #1e1e20;
    color: white;
    box-shadow: none;
    padding: 0 20px;
  }
}

.sheet-fan-area-menu-backdrop {
  bottom: 59px !important;
}

.lds-ripple {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 40px;
    left: 40px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}

.ngx-pagination {
  margin-bottom: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  gap: 6px;

  li {
    width: 32px !important;
    height: 32px !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    background: #1e1e24 !important;
    font-size: 14px !important;
    border-radius: 6px !important;
    transition: all 0.2s !important;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: #5e636d !important;
      background: transparent !important;
      text-decoration: none !important;
    }

    &.pagination-previous {
      &:not(:has(a))::before,
      a::before {
        content: '\e923' !important; // icon-Arrow-Left
        margin: 0 !important;
        font-family: 'icomoon' !important;
        color: #5e636d;
        font-size: 12px !important;
      }
    }

    &.pagination-next {
      &:not(:has(a))::after,
      a::after {
        content: '\e924' !important; // icon-Arrow-Right
        margin: 0 !important;
        font-family: 'icomoon' !important;
        color: #5e636d;
        font-size: 12px !important;
      }
    }

    &.ellipsis {
      background: transparent !important;
      a {
        span {
          font-size: 0 !important;
          &::after {
            content: '\e91f'; // icon-Dots
            font-family: 'icomoon' !important;
            font-size: 12px !important;
            color: #5e636d !important;
          }
        }
      }
    }

    &:hover {
      background: #2b2660 !important;
    }

    &.current {
      background: #776bf8 !important;

      a {
        color: #fff !important;
      }
    }

    &.small-screen {
      display: none !important;
    }
  }
}

menu-dropdown {
  .text {
    font-size: 14px;
  }
}
